.imageLoader {
  border-radius: 4px;
  display: block;
  height: 0;
  margin: 0 auto 8px;
  overflow: hidden;
  padding-bottom: 88px;
  position: relative;
  width: 88px;
  z-index: 1;
  img {
    display: block;
    height: auto;
    left: 0;
    margin: 0 auto;
    min-height: 1px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    &.popIn {
      animation: pop-in 0.18s;
    }
  }
  &.notLoaded {
    background-color: transparent;
    border-radius: 4px;
    img {
      -ms-transform: translate(
        1000%,
        1000%
      ); /* added vendor specific css (IE) */
      -webkit-transform: translate(1000%, 1000%);
      transform: translate(1000%, 1000%);
    }
  }
}
