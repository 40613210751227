@use '../../../../styles/variables.scss' as variables;

.articleItem {
  background-color: variables.$secondary-color;
  height: 100%;
  width: 100%;
  padding: 20px 30px;
  padding-bottom: 50px;
  h3 {
    color: variables.$gold;
    font-size: 1.2rem;
    margin: 5px auto;
    width: 100%;
  }
  h4 {
    font-size: 1.1rem;
    margin: 30px 0 0 0;
  }
  p {
    color: variables.$text-color;
    display: inline-block;
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  ul {
    list-style: none;
    margin: 0 auto;
    padding-inline-start: 0;
    > li {
      align-items: flex-start;
      display: flex;
      &::before {
        content: '\2022'; /* Unicode for bullet */
        font-size: 1.2em;
        padding-left: 18px;
        position: relative;
        top: 1px;
      }
      > p {
        margin: 0 0 5px 0;
        padding-left: 18px;
      }
    }
  }
  > span {
    color: variables.$text-color;
    display: inline-block;
    font-size: 0.75rem;
    position: relative;
    text-align: left;
    width: 100%;
    &.preview {
      text-align: right;
    }
  }
  &.preview {
    align-items: center;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 140px;
    justify-content: flex-start;
    margin: 7px 0;
    max-width: 100%;
    padding: 10px 20px 5px;
    padding-bottom: 10px;
    text-decoration: none;
    width: 50%;
    h3 {
      color: variables.$gold;
      font-size: 1.1rem;
    }
    p {
      color: variables.$text-color;
    }
    > span {
      text-align: left;
      &:nth-child(2) {
        color: lightgray;
        margin: 0 auto 5px;
      }
    }
  }
  .affiliateProducts {
    align-items: center;
    align-self: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 320px;
  }
  .affiliateNote {
    color: #dedede;
    font-size: 0.8rem;
  }
  .content {
    overflow: hidden;
    overflow-wrap: break-word; /* Alternative for better support */
    text-overflow: ellipsis;
    width: 100%;
    word-wrap: break-word; /* Ensures long words wrap */
    white-space: normal;
    &.preview {
      p {
        font-size: 0.85rem;
        margin: 0;
      }
    }
  }
  div[id='youtube-container'] {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .articleItem {
    &.preview {
      width: 80%;
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .articleItem {
    padding: 10px;
    &.preview {
      height: 165px;
      width: 80%;
    }
  }
}

@media screen and (max-width: 575px) {
  .articleItem {
    padding: 10px;
    p {
      font-size: 1rem;
    }
    &.preview {
      height: 165px;
      width: 95%;
    }
    .affiliateProducts {
      width: 95%;
    }
    .affiliateNote {
      width: 95%;
    }
  }
}
