@use '../../../styles/variables.scss' as variables;

.imageBackground {
  height: 100vh;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  .bgContainer {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
  .overlay {
    background-color: black;
    height: 100%;
    left: 0;
    opacity: 0.8;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
  }
}
