.cardsSearch {
  height: 100vh;
  padding: 15px 0;
  &.gallery {
    height: 55vh;
  }
  &.filters {
    height: unset;
    min-height: 84.5vh;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .cardsSearch {
    height: 94vh;
    &.filters {
      height: unset;
      min-height: 83.5vh;
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .cardsSearch {
    height: 86.75vh;
    &.filters {
      height: unset;
      min-height: 86.75vh;
    }
  }
}

@media screen and (max-width: 575px) {
  .cardsSearch {
    height: 90vh;
    &.filters {
      height: unset;
      min-height: 70vh;
    }
  }
}
