.productItem {
  margin: 0 10px;
  visibility: visible;
  width: 260px;
  &.visible {
    animation: pop-in 0.3s;
  }
  > a {
    display: inline-block;
    width: 260px;
    > span {
      &.imageLoader {
        height: auto;
        margin: 0 auto;
        width: inherit;
      }
    }
  }
}
