@use './variables.scss' as variables;

@font-face {
  font-family: '#{variables.$keyrune_font_face}';
  src: url('#{variables.$keyrune_font_path}' + '/keyrune.eot?v=' + '#{variables.$keyrune_version}');
  src:
    url('#{variables.$keyrune_font_path}' + '/keyrune.eot?#iefix&v=' + '#{variables.$keyrune_version}')
      format('embedded-opentype'),
    url('#{variables.$keyrune_font_path}' + '/keyrune.woff2?v=' + '#{variables.$keyrune_version}')
      format('woff2'),
    url('#{variables.$keyrune_font_path}' + '/keyrune.woff?v=' + '#{variables.$keyrune_version}')
      format('woff'),
    url('#{variables.$keyrune_font_path}' + '/keyrune.ttf?v=' + '#{variables.$keyrune_version}')
      format('truetype'),
    url('#{variables.$keyrune_font_path}' + '/keyrune.svg?v=' + '#{variables.$keyrune_version}' + '#keyrune')
      format('svg');
  font-weight: variables.$keyrune_font_weight;
  font-style: variables.$keyrune_font_style;
}
