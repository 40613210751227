@use '../../../styles/variables.scss' as variables;

.searchBar {
  min-height: 200px;
  margin: 68px auto 10px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  width: 100%;
  .advancedButton {
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    font-size: 0.75rem;
    width: 100%;
  }
  .searchInput {
    left: 0;
    position: absolute;
    right: 0;
    top: 120px;
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .searchBar {
    margin: 50px auto 10px;
    .advancedButton {
      font-size: 0.6rem;
    }
    .searchInput {
      width: 90%;
    }
  }
}
