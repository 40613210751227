@use '../../../../styles/variables.scss' as variables;

.cardsGallery {
  align-items: flex-start;
  box-shadow:
    0 0 10px rgba(255, 215, 0, 0.4),
    /* Soft outer glow */ 0 0 20px rgba(255, 215, 0, 0.3),
    0 0 30px rgba(255, 215, 0, 0.2),
    inset 0 0 10px rgba(255, 215, 0, 0.5); /* Inner glow for richness */
  display: flex;
  flex-direction: column;
  height: 420px;
  justify-content: center;
  padding: 0 10px;
  position: relative;
  > h3 {
    color: #ffd700;
    font-family: 'AvenirHeavy';
    font-size: 1.1rem;
    margin: 5px 0 0 10px;
    position: absolute;
    top: 12px;
    z-index: 2;
  }
  > .container {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    padding: 0 8px;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    > .column {
      height: 334px;
      margin: 5px;
      width: 240px;
      > .cardImage {
        height: 334px;
        width: 240px;
        > a {
          > span {
            &.cardImageContainer {
              padding-bottom: 332px;
            }
          }
        }
      }
    }
  }
  > .overlay {
    background-color: variables.$secondary-color;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .cardsGallery {
    height: 400px;
    > .container {
      > .column {
        height: 332px;
        width: 230px !important;
        > .cardImage {
          height: 320px !important;
          width: 230px !important;
          > a {
            > span {
              &.cardImageContainer {
                padding-bottom: 319px !important;
                width: 230px;
                > img {
                  width: 230px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .cardsGallery {
    height: 380px;
    > .container {
      > .column {
        height: 300px;
        width: 215px !important;
        > .cardImage {
          height: 300px;
          width: 215px !important;
          > a {
            > span {
              &.cardImageContainer {
                padding-bottom: 299px !important;
                width: 215px;
                > img {
                  width: 215px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .cardsGallery {
    height: 360px;
    > h3 {
      top: 10px;
    }
    .container {
      > .column {
        height: 273px;
        width: 194px !important;
        > .cardImage {
          height: 270px !important;
          width: 194px !important;
          > a {
            > span {
              &.cardImageContainer {
                padding-bottom: 269px !important;
                width: 194px;
                > img {
                  width: 194px;
                }
              }
            }
          }
        }
      }
    }
  }
}
