@use '../../../styles/variables.scss' as variables;

.dashboard {
  min-height: 85vh;
}

@media screen and (max-width: 575px) {
  .dashboard {
    .searchInput {
      top: 100px;
      width: 90%;
    }
  }
}
