@use '../../../styles/variables.scss' as variables;

.checkbox {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;
  > input[type='checkbox'] {
    -moz-osx-font-smoothing: grayscale;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    appearance: none;
    color: variables.$primary-color;
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    font-size: 1.4rem;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    margin-right: 8px;
    speak: never;
    text-transform: none;
    &:before {
      content: '\ea53';
    }
    &:checked {
      &:before {
        content: '\ea52';
      }
    }
  }
  label {
    font-size: 0.8rem;
  }
  .icon {
    margin: 0;
    padding-bottom: 0px;
    width: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .checkbox {
    label {
      font-size: 0.9rem;
      margin: 5px 0 0 7px;
    }
  }
}

@media screen and (max-width: 767px) {
  .checkbox {
    label {
      font-size: 0.8rem;
      margin: 5px 0 0 7px;
    }
    > input {
      margin-right: 2px !important;
    }
    .icon {
      margin: 2px 0 0 7px;
    }
  }
}
