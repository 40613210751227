@use '../../../../styles/variables.scss' as variables;

.cardsSearchFilters {
  animation: popIn 0.5s ease-out;
  background: transparent;
  position: relative;
  width: 100%;
  > .content {
    background: transparent;
    margin: 30px auto 0;
    padding: 10px 15px;
    width: 100%;
    > form {
      height: 100%;
      margin: 0 auto;
      padding: 15px;
      width: 50%;
    }
    input[type='input'] {
      background: variables.$secondary-color !important;
    }
    .background {
      background-position: left !important;
      background-size: cover;
      border-radius: 6px;
      box-shadow: 0 0 9px 5px rgba(255, 215, 0, 0.6);
      margin: 0 auto;
      height: 99%;
      overflow: hidden;
      width: 50%;
    }
    .bgOverlay {
      box-shadow: 0 0 9px 5px rgba(255, 215, 0, 0.6);
      margin: 0 auto;
      height: 100%;
      width: 100%;
    }
    .buttonContainer {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 50px auto 0;
      padding-bottom: 15px;
      position: relative;
      width: 100%;
    }
    .checkboxes {
      margin-bottom: 15px;
      position: relative;
      top: 10px;
      > label {
        color: #fff;
        display: block;
        font-size: 0.875rem;
        font-weight: 900;
        left: 5px;
        margin-bottom: 5px;
        position: relative;
        width: 100%;
      }
      .checkbox {
        &.column {
          justify-content: space-evenly;
          .colorCheckbox {
            width: 50%;
          }
        }
      }
    }
    .closeButton {
      background-color: transparent;
      box-shadow: none;
      height: 20px;
      padding: 0;
      position: relative;
      right: -95%;
      top: 0;
      width: 20px;
      [class^='icon-cross'],
      [class*=' icon-cross'] {
        font-size: 0.8rem;
      }
      &:hover {
        [class^='icon-cross'],
        [class*=' icon-cross'] {
          color: variables.$gold-darker;
        }
      }
    }
    .colorIcon {
      margin-left: 7px;
      padding-bottom: 21px;
      top: 1px;
      width: 21px;
    }
    .dropdown {
      margin-bottom: 2px !important;
    }
    .inputText {
      margin-bottom: 15px !important;
    }
    .row {
      margin: 10px auto;
      &.column {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        .dropdown {
          width: 40%;
          > select {
            width: 100%;
          }
        }
        .inputText {
          margin: 13px 10px 10px 0;
          width: 60%;
        }
        &.even {
          width: 100%;
          .inputText {
            margin: 4px auto;
            &:nth-child(1) {
              margin-right: 4px;
            }
            &:nth-child(2) {
              margin-left: 4px;
            }
          }
        }
        &.legality {
          .dropdown {
            width: 50%;
            > select {
              width: 100%;
            }
          }
          .radioButtons {
            width: 50%;
          }
        }
        &.set {
          .dropdown {
            &:nth-child(2) {
              width: 40%;
              > select {
                width: 100%;
              }
            }
          }
          .setsDropdown {
            margin-right: 10px;
            width: 60%;
            > .inputText {
              width: 100%;
            }
          }
        }
      }
      .inputText {
        margin: 4px auto;
      }
    }
  }
}

@keyframes popIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  60% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .cardsSearchFilters {
    > .content {
      > form {
        width: 65%;
      }
      .background {
        width: 65%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .cardsSearchFilters {
    > .content {
      margin-top: 30px;
      width: 100%;
      > form {
        padding: 7px;
        width: 90%;
      }
      .background {
        width: 90%;
      }
      .buttonContainer {
        justify-content: center;
        margin: 10px auto;
      }
      .checkboxes {
        margin-bottom: 15px;
        position: relative;
        top: -3px;
      }
      .row {
        &.column {
          display: block;
          .dropdown {
            width: 100%;
            > select {
              width: 100%;
            }
          }
          .inputText {
            margin: 10px auto;
            width: 100%;
          }
          &.even {
            width: 100%;
            .inputText {
              &:nth-child(1) {
                margin-right: 0;
              }
              &:nth-child(2) {
                margin-left: 0;
              }
            }
          }
          &.legality {
            width: 100%;
            .dropdown {
              width: 100%;
              > select {
                width: 100%;
              }
            }
            .radioButtons {
              width: 100%;
            }
          }
          &.set {
            .dropdown {
              &:nth-child(2) {
                width: 100%;
                > select {
                  width: 100%;
                }
              }
            }
            .setsDropdown {
              width: 100%;
              > .inputText {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
