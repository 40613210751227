@use '../../../styles/variables.scss' as variables;

.troubleshoot {
  align-items: center;
  background-color: variables.$secondary-color-lighter;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  position: fixed;
  right: 90px;
  top: 55%;
  width: 32%;
  z-index: 99;
  &.hidden {
    transform: translateY(100%);
  }
  &.slide-up {
    animation: slideUp 0.5s ease-out;
  }
  > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 5px 12px;
    width: 100%;
    .email {
      color: variables.$gold;
      text-decoration: none;
    }
    button {
      > span {
        margin-right: 7px;
      }
      &.troubleshootButton {
        background-color: variables.$primary-color-lighter;
        width: 65%;
        &:hover {
          background-color: variables.$primary-color-darker;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .troubleshoot {
    width: 40%;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .troubleshoot {
    width: 79%;
  }
}
@media screen and (max-width: 575px) {
  .troubleshoot {
    width: 79%;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
