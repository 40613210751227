@use '../../../styles/variables.scss' as variables;

.dropdown {
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 4px;
  box-shadow: none;
  color: variables.$text-color;
  display: block;
  font-size: 0.875rem;
  font-weight: 900;
  min-height: 40px;
  outline: none;
  position: relative;
  width: 100%;
  > label {
    left: 4px;
    position: relative;
    top: 3px;
  }
  > select {
    background-color: variables.$secondary-color !important;
    background-position: right 0 top 2px;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 8px auto 0;
    padding: 0 10px;
  }
  option {
    background-color: variables.$primary-color;
    color: variables.$text-color;
    text-shadow: none;
  }
  &:disabled {
    opacity: 0.5;
  }
  &:focus {
    border-color: variables.$primary-color;
    box-shadow: 0 1px 0px 0 variables.$primary-color;
  }
  &.withErrors {
    border: 1px solid #fd1f33 !important;
  }
  &.withFocus {
    box-shadow: 0 0 3px 2px variables.$primary-color;
  }
  &.default {
    color: #757575;
    font-weight: 100;
  }
}
