@use '../../../../styles/variables.scss' as variables;

.setsDropdown {
  > .inputText {
    input {
      background-color: variables.$secondary-color;
      padding: 10px 10px 10px 60px;
    }
    [class^='icon-search'],
    [class*=' icon-search'] {
      top: 36px;
    }
    > i {
      left: 40px;
      position: absolute;
      top: 35px;
    }
  }
  .filters {
    animation: popup 0.8s ease-in-out forwards;
    background-color: variables.$secondary-color-lighter;
    left: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 200px;
    margin: 0 auto;
    padding: 5px;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 3;
    p {
      padding: 15px;
    }
    > ul {
      padding: 5px 10px;
      > li {
        list-style: none;
        padding-left: 10px;
        > button {
          align-items: center;
          border: none;
          background-color: transparent;
          display: flex;
          font-size: 0.9rem;
          justify-content: flex-start;
          margin: 2px 0;
          text-align: left;
          text-transform: capitalize;
          width: 100%;
          > i {
            align-items: center;
            bottom: 1px;
            display: flex;
            font-size: 1rem;
            justify-content: flex-start;
            line-height: 1.3;
            position: relative;
            width: 7.5%;
          }
          > p {
            margin: 0;
            padding: 0;
            width: 92.5%;
          }
        }
        &:hover {
          background-color: variables.$secondary-color;
        }
      }
    }
    .closeButton {
      background-color: transparent;
      box-shadow: none;
      margin: 0;
      padding: 0;
      position: absolute;
      right: 10px;
      top: 15px;
    }
  }
}

@keyframes popup {
  50% {
    transform: scale(1.0005);
  }
}
