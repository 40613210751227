@use '../../../../../styles/variables.scss' as variables;

.cardSynergyItem {
  align-items: center;
  background-color: variables.$secondary-color;
  border-radius: 8px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  height: 440px;
  justify-content: space-between;
  margin-top: 10px;
  padding: 5px;
  width: 33%;
  &.noBackground {
    background-color: unset !important;
    border-radius: unset !important;
    box-shadow: unset !important;
    width: 408px;
    .cardImage {
      height: 418px;
      width: 300px;
    }
  }
  > span {
    &.description {
      height: 100%;
      overflow-y: scroll;
    }
    p {
      color: variables.$text-color;
      font-size: 0.85rem;
      margin: 0;
      padding: 5px 10px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .cardSynergyItem {
    width: 49%;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .cardSynergyItem {
    width: 49%;
    .cardImage {
      height: 334px !important;
      width: 240px !important;
    }
    &.noBackground {
      .cardImage {
        height: 418px !important;
        width: 300px !important;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .cardSynergyItem {
    height: 500px;
    width: 100%;
    .cardImageContainer {
      padding-bottom: 402px !important;
    }
  }
}
