.products {
  align-items: center;
  display: flex;
  height: 260px;
  justify-content: center;
  padding: 0 20px;
  width: 320px;
  > .container {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: hidden;
    width: inherit;
  }
}

@media screen and (max-width: 575px) {
  .products {
    align-self: center;
    > .container {
      align-self: center !important;
    }
  }
}
