@use '../../../styles/variables.scss' as variables;

.footer {
  align-items: center;
  background: variables.$body-bg;
  bottom: 0;
  box-shadow: variables.$neon-green-box-shadow;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  .disclaimer {
    margin: 10px auto;
    position: relative;
    width: 100%;
    p {
      font-size: 0.75rem;
      margin: 2px auto;
      text-align: center;
      width: 90%;
    }
  }
}
