@use './icomoon';
@use './keyframes.scss';
@use './keyrune/keyrune.scss';
@use './variables' as variables;

@font-face {
  font-family: 'AvenirHeavy';
  src: url('../assets/fonts/Avenir-Heavy.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'AvenirMedium';
  src: url('../assets/fonts/Avenir-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'AvenirRegular';
  src: url('../assets/fonts/Avenir-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'mplantin';
  src: url('../assets/fonts/mplantin/mplantin.eot?mr9i2k');
  src:
    url('../assets/fonts/mplantin/mplantin.eot?mr9i2k#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/mplantin/mplantin.ttf?mr9i2k') format('truetype'),
    url('../assets/fonts/mplantin/mplantin.woff?mr9i2k') format('woff'),
    url('../assets/fonts/mplantin/mplantin.svg?mr9i2k#mplantin') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: variables.$body-bg;
  color: variables.$text-color;
  font-family:
    'AvenirMedium',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  font-weight: 400;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  perspective: 1000px;
  width: 100%;
  .clickable {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    outline: 0;
  }
}

* {
  box-shadow: none;
  box-sizing: border-box;
  color: variables.$text-color;
  font-family:
    'AvenirMedium',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  outline: none;
}

#app {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

/* Webkit browsers (Chrome, Safari, Edge) */
body::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

body::-webkit-scrollbar-track {
  background-color: variables.$secondary-color; /* Color of the track (the area the scrollbar moves within) */
}

body::-webkit-scrollbar-thumb {
  background-color: variables.$primary-color; /* Color of the scrollbar thumb (the draggable part) */
  border-radius: 10px; /* Optional: round the corners of the thumb */
}

body::-webkit-scrollbar-thumb:hover {
  background-color: variables.$primary-color-lighter; /* Color of the thumb when hovered */
}

/* Firefox */
#app {
  scrollbar-width: thin; /* Makes the scrollbar thinner */
  scrollbar-color: variables.$primary-color variables.$secondary-color; /* scrollbar thumb color, scrollbar track color */
}
