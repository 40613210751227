@use '../../../../styles/variables.scss' as variables;

.deck {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  > div {
    &.cardGroups {
      > h3 {
        color: variables.$tertiary-color;
        font-family: 'AvenirMedium';
        font-size: 1.05rem;
        font-weight: 800;
        margin-top: 15px;
        text-transform: capitalize;
      }
      > div {
        display: flex;
        flex-direction: column;
        > a {
          max-height: 25px;
          max-width: max-content;
          &:hover {
            > p {
              color: variables.$gold-darker;
            }
          }
          > p {
            &.cards {
              font-size: 1rem;
              margin: 2px;
              width: auto;
            }
          }
        }
      }
    }
  }
}
