@use '../../../../styles/variables.scss' as variables;

.cardItem {
  margin: 0 auto;
  align-items: flex-start;
  background: variables.$secondary-color;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 90vh;
  padding: 40px;
  width: 100%;
  &.full {
    height: 100vh;
  }
  .additionalInfo {
    background-color: black;
    border-radius: 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    min-height: 60px;
    margin: 10px 0;
    position: relative;
    width: 320px;
    > span {
      align-items: center;
      color: variables.$primary-color;
      display: flex;
      justify-content: flex-start;
      margin: 0;
      padding: 10px;
      > i {
        align-items: center;
        display: flex;
        font-size: 2rem;
        justify-content: center;
        width: 15%;
      }
      > span {
        &.setInfo {
          align-items: center;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: flex-start;
          width: 85%;
          > p {
            color: variables.$text-color;
            font-family: 'AvenirRegular';
            font-size: 1rem;
            font-weight: 100;
            margin: 2px auto;
            padding: 0;
            text-align: left;
            width: 100%;
            &:nth-child(1) {
              font-weight: 600;
            }
            &:nth-child(2) {
              font-size: 0.75rem;
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
  .content {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
  }
  .details {
    align-items: center;
    background-color: variables.$light-gray;
    border: 14px solid variables.$card-border-color;
    border-radius: 4% / 3%; /* Black border similar to MTG cards */
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    left: 5px;
    margin: 3px 0;
    margin-top: 15px;
    min-height: 325px;
    padding: 5px 10px;
    position: relative;
    width: 300px;
    > div {
      width: 100%;
      .separator {
        background-color: variables.$secondary-color;
      }
    }
    h3 {
      align-items: center;
      font-size: 1.1rem;
      display: flex;
      justify-content: space-between;
      width: 100%;
      > span {
        align-items: center;
        display: flex;
        &:nth-child(1) {
          justify-self: start;
          width: 65%;
        }
        &:nth-child(2) {
          justify-content: flex-end;
          width: 35%;
        }
      }
    }
    h3,
    p,
    span {
      color: variables.$secondary-color;
    }
    p {
      font-size: 1rem;
    }
    &.noBorder {
      background-color: black;
      border: 10px solid black;
      border-radius: 0;
      left: 0;
      margin-top: 10px;
      width: 100%;
      &.formatsContainer {
        padding: 0;
        width: 320px;
      }
      &.rulings {
        background-color: transparent;
        border: none !important;
        border-radius: 0 !important;
        margin: 12px auto;
        min-height: 100px;
        .ruling {
          display: inline-block;
          overflow: hidden;
          width: 100%;
          > p {
            color: variables.$text-color;
            font-size: 0.9rem;
          }
          > p {
            > span {
              color: variables.$text-color;
            }
          }
          .separator {
            background-color: #c1ffb3;
            height: 0.5px;
          }
        }
      }
      h3,
      p,
      span {
        color: variables.$text-color;
      }
    }
    .cardSymbol {
      -webkit-border-radius: 500px;
      -webkit-box-shadow: -1px 1px 0 rgba(0, 0, 0, 0.85);
      border-radius: 500px;
      box-shadow: -1px 1px 0 rgba(0, 0, 0, 0.85);
      display: inline-block;
      height: 16px;
      margin: 0 1px;
      padding: 0;
      width: 16px;
    }
    .flavorText {
      font-family: 'mplantin';
      font-style: italic;
    }
    .formats {
      align-items: center;
      border-radius: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 100%;
      .format {
        align-items: center;
        display: flex;
        height: 40px;
        justify-content: center;
        width: 50%;
        > p {
          &:nth-child(1) {
            font-size: 0.6rem;
          }
          &:nth-child(2) {
            background-color: variables.$secondary-color;
            border-radius: 6px;
            color: variables.$gold;
            font-weight: 400;
            &.illegal {
              background-color: #2a302a;
              color: #bbb;
            }
          }
          align-items: center;
          display: flex;
          font-size: 0.675rem;
          height: 25px;
          justify-content: center;
          padding: 2px;
          text-align: center;
          text-transform: uppercase;
          width: 50%;
        }
      }
    }
    .separator {
      display: block;
      background-color: variables.$text-color;
      height: 0.5px;
      margin: 3px auto;
      width: 95%;
      &.full {
        width: 100%;
      }
    }
    .typeAndRarity {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
      > span {
        &:nth-child(1) {
          font-size: 0.95rem;
          width: 65%;
        }
        &.rarity {
          align-items: center;
          display: flex;
          justify-content: flex-end;
          width: 35%;
          i {
            align-items: center;
            font-size: 1.4rem;
            display: flex;
            justify-content: flex-end;
          }
          p {
            font-size: 0.8rem;
            padding: 5px;
            text-align: right;
          }
        }
      }
    }
  }
  .imageContainer {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    width: 50%;
    .cardImage {
      height: 417px;
      left: 5px;
      margin: 0 !important;
      position: relative;
      top: 5px;
      width: 300px;
      > a,
      span > .cardImageContainer {
        height: 417px;
        padding-bottom: 417px;
        width: 300px;
      }
    }
  }
  .lowerHalf,
  .upperHalf {
    align-items: flex-start;
    display: flex;
    justify-content: space-evenly;
    overflow-x: hidden;
    width: 100%;
  }
  .upperHalf {
    padding-bottom: 5px;
  }
  .lowerHalf {
    border-top: 0.5px #c1ffb3 solid;
    flex-direction: column;
    margin-top: 10px;
  }
  .prints {
    align-items: center;
    background-color: black !important;
    border-radius: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2px 0;
    padding: 5px 10px;
    padding-bottom: 10px;
    position: relative;
    width: 632px !important;
    button {
      align-items: center;
      background: variables.$secondary-color;
      border-radius: 0;
      display: flex;
      height: 39px;
      justify-content: space-around;
      margin: 2px auto;
      transition: box-shadow 0.2s ease-in-out;
      width: 49%;
      > i {
        font-size: 1.1rem;
        width: 15%;
      }
      > span {
        &.setInfo {
          align-items: center;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          width: 85%;
          > p {
            color: variables.$text-color;
            font-family: 'AvenirMedium';
            font-size: 0.85rem;
            font-weight: 100;
            margin: 2px auto;
            padding: 0;
            text-align: left;
            width: 100%;
          }
        }
      }
    }
    h3 {
      align-items: center;
      color: variables.$text-color;
      display: flex;
      font-size: 0.8rem;
      justify-content: flex-start;
      margin: 0;
      padding: 10px 0;
      width: 100%;
    }
    &.single {
      width: 316px !important;
      button {
        width: 95%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .cardItem {
    padding: 15px 0;
    button {
      > i {
        font-size: 1.4rem !important;
      }
      > span {
        &.setInfo {
          > p {
            font-size: 0.9rem !important;
          }
        }
      }
    }
    .additionalInfo,
    .prints {
      left: 0;
      width: 100%;
    }
    .content {
      margin: 0 auto;
      width: 95%;
    }
    .details {
      left: 0;
      width: 95%;
    }
    .imageContainer {
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: 100%;
      .cardImage {
        height: 460px;
        left: -5px;
        margin: 0 !important;
        position: relative;
        top: 5px;
        width: 330px;
        > a,
        span > .cardImageContainer {
          height: 460px;
          padding-bottom: 459px;
          width: 330px;
        }
      }
    }
    .formats {
      .format {
        > p {
          &:nth-child(1) {
            font-size: 0.7rem !important;
          }
          &:nth-child(2) {
            font-size: 0.72rem !important;
          }
        }
      }
    }
    .formatsContainer {
      width: 100% !important;
    }
    .lowerHalf,
    .upperHalf {
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 100%;
    }
    .prints {
      flex-direction: column;
      flex-wrap: unset;
      width: 95% !important;
      &.single {
        width: 95% !important;
      }
      button {
        width: 100%;
      }
    }
    .rulings {
      width: 95% !important;
    }
    .upperHalf {
      flex-direction: column-reverse;
    }
  }
}
