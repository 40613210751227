@use 'sass:math';
@use '../../../../styles/variables.scss' as variables;

.animatedCards {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  div {
    backface-visibility: hidden;
    border-radius: 5px;
    box-sizing: border-box;
    display: inline-block;
    height: 13vh; /* 15% of the viewport height */
    position: absolute;
    text-align: center;
    top: 25%;
    transform: rotate(0deg);
    transform-origin: bottom left;
    width: 13vw; /* 15% of the viewport width */
    z-index: 1;
    img {
      height: auto;
      width: 13vw; /* 15% of the viewport width */
    }
  }
  .animated {
    animation-iteration-count: infinite;
  }
  div:nth-child(1) {
    animation:
      swatch-card1-motion 4s cubic-bezier(0.4, 0, 0.2, 1) forwards,
      spread-card1 2s cubic-bezier(0.4, 0, 0.2, 1) 4s forwards,
      show-card1 3s cubic-bezier(0.4, 0, 0.2, 1) 12s forwards;
  }
  div:nth-child(2) {
    animation:
      swatch-card2-motion 4s cubic-bezier(0.4, 0, 0.2, 1) forwards,
      spread-card2 2s cubic-bezier(0.4, 0, 0.2, 1) 5s forwards,
      show-card2 3s cubic-bezier(0.4, 0, 0.2, 1) 15s forwards;
  }
  div:nth-child(3) {
    animation:
      swatch-card3-motion 4s cubic-bezier(0.4, 0, 0.2, 1) forwards,
      spread-card3 2s cubic-bezier(0.4, 0, 0.2, 1) 6s forwards,
      show-card3 3s cubic-bezier(0.4, 0, 0.2, 1) 18s forwards;
  }
  div:nth-child(4) {
    animation:
      swatch-card4-motion 4s cubic-bezier(0.4, 0, 0.2, 1) forwards,
      spread-card4 2s cubic-bezier(0.4, 0, 0.2, 1) 7s forwards,
      show-card4 3s cubic-bezier(0.4, 0, 0.2, 1) 21s forwards;
  }
  div:nth-child(5) {
    animation:
      swatch-card5-motion 4s cubic-bezier(0.4, 0, 0.2, 1) forwards,
      spread-card5 2s cubic-bezier(0.4, 0, 0.2, 1) 8s forwards,
      show-card5 3s cubic-bezier(0.4, 0, 0.2, 1) 24s forwards;
  }
  div:nth-child(6) {
    animation:
      swatch-card6-motion 4s cubic-bezier(0.4, 0, 0.2, 1) forwards,
      spread-card6 2s cubic-bezier(0.4, 0, 0.2, 1) 9s forwards,
      show-card6 3s cubic-bezier(0.4, 0, 0.2, 1) 27s forwards;
  }
  div:nth-child(7) {
    animation:
      swatch-card7-motion 4s cubic-bezier(0.4, 0, 0.2, 1) forwards,
      spread-card7 2s cubic-bezier(0.4, 0, 0.2, 1) 10s forwards,
      show-card7 3s cubic-bezier(0.4, 0, 0.2, 1) 30s forwards;
  }
}

@mixin forward($xPixels, $yPixels) {
  0%,
  100% {
    transform: translate($xPixels, 0);
    z-index: 5;
  }
  50% {
    transform: scale(1.3) translate(0, $yPixels);
    z-index: 5;
  }
}
@mixin transform($fromDeg, $toDeg) {
  0% {
    transform: rotate($fromDeg);
  }
  30%,
  70% {
    transform: rotate($toDeg);
  }
  90%,
  10% {
    transform: rotate($fromDeg);
  }
}
@mixin translateCard($pixels) {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX($pixels);
  }
}

@keyframes show-card1 {
  @include forward(-150px, -30px);
}
@keyframes show-card2 {
  @include forward(-100px, -30px);
}
@keyframes show-card3 {
  @include forward(-50px, -30px);
}
@keyframes show-card4 {
  @include forward(0px, -30px);
}
@keyframes show-card5 {
  @include forward(50px, -30px);
}
@keyframes show-card6 {
  @include forward(100px, -30px);
}
@keyframes show-card7 {
  @include forward(150px, -30px);
}

@keyframes swatch-card1-motion {
  @include transform(0deg, -45deg);
}
@keyframes swatch-card2-motion {
  @include transform(0deg, -30deg);
}
@keyframes swatch-card3-motion {
  @include transform(0deg, -15deg);
}
@keyframes swatch-card4-motion {
  @include transform(0deg, 0deg);
}
@keyframes swatch-card5-motion {
  @include transform(0deg, 15deg);
}
@keyframes swatch-card6-motion {
  @include transform(0deg, 30deg);
}
@keyframes swatch-card7-motion {
  @include transform(0deg, 45deg);
}

@keyframes spread-card1 {
  @include translateCard(-150px);
}
@keyframes spread-card2 {
  @include translateCard(-100px);
}
@keyframes spread-card3 {
  @include translateCard(-50px);
}
@keyframes spread-card4 {
  @include translateCard(0);
}
@keyframes spread-card5 {
  @include translateCard(50px);
}
@keyframes spread-card6 {
  @include translateCard(100px);
}
@keyframes spread-card7 {
  @include translateCard(150px);
}

@keyframes rotate-cards {
  100% {
    transform: rotate(-30deg);
  }
}
@keyframes rotate-cards-original {
  100% {
    transform: rotate(0deg);
  }
}
