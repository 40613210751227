@use '../../../../styles/variables.scss' as variables;

.articlesSearchBar {
  height: 100px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  > .searchBar {
    height: 100px;
    min-height: unset !important;
    margin: 0 auto;
    > form {
      > .searchInput {
        top: 30%;
      }
    }
  }
}
