@use 'sass:math';
@use './variables.scss' as variables;
/**
 * Sizes */
@each $class, $size in variables.$keyrune_sizes {
  .#{variables.$keyrune_prefix}.#{variables.$keyrune_prefix}-#{$class} {
    font-size: #{$size};
  }
}

/**
 * Fixed width */
.#{variables.$keyrune_prefix}.#{variables.$keyrune_prefix}-fw {
  width: calc(
    18em /
      #{math.div(
        variables.$keyrune_font_size,
        variables.$keyrune_font_size * 0 + 1
      )}
  );
  text-align: center;
}

/**
 * Force no border */
.#{variables.$keyrune_prefix}.#{variables.$keyrune_prefix}-no-border {
  -webkit-text-stroke: 0;
  text-stroke: 0;
}

/**
 * Icon borders */
.#{variables.$keyrune_prefix}.#{variables.$keyrune_prefix}-border {
  &:after {
    content: '';
    position: absolute;
    left: -0.05em;
    top: 0em;
    z-index: -1;
    color: variables.$white;
    font-size: 1.15em;
    background: variables.$white;
    -webkit-text-stroke: 0.05em variables.$white;
    -webkit-background-clip: variables.$keyrune_background_clip;
    -webkit-text-fill-color: variables.$keyrune_text_fill;
  }

  // adding a white version of the symbol for these problem sets,
  // instead of using the generic text-stroke above
  @each $set, $code, $glyph in variables.$border-corrections {
    &.#{variables.$keyrune_prefix}-#{$code}:after {
      /* #{$set} */
      content: variables.ss-content($glyph);
    }
  }

  // corrections for various duo icons
  // SIS: the regular symbol becomes the border now
  &.#{variables.$keyrune_prefix}-sis {
    &::before {
      content: '\e9b3';
    }
    &::after {
      content: '\e9b2';
    }
  }
  // c16: regular symbol becomes the border
  &.#{variables.$keyrune_prefix}-c16 {
    &::before {
      content: '\e910'; // rarity
    }
    &::after {
      content: '\e9e5'; // new border
    }
  }
  // dft: regular symbol becomes the border
  &.#{variables.$keyrune_prefix}-dft {
    &::before {
      content: '\e9e1'; // rarity
    }
    &::after {
      content: '\e9e0'; // new border
    }
  }
}

/**
 * Dual-layered symbols */
.#{variables.$keyrune_prefix}.#{variables.$keyrune_prefix}-duo {
  // base border styles
  &::after {
    color: #000;
    content: '';
    display: block;
    font-size: 1em;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  & {
    // set default inner to white unless a rarity is declared
    color: #fff;
  }
  // sets
  @each $code, $glyph in variables.$dual-layered {
    &.#{variables.$keyrune_prefix}-#{$code}::after {
      content: variables.ss-content($glyph);
    }
  }

  // manual corrections
  &.#{variables.$keyrune_prefix}-ncc::before {
    content: '\e98f';
  }
  &.#{variables.$keyrune_prefix}-40k::before {
    content: '\e999';
  }
  &.#{variables.$keyrune_prefix}-dmc::before {
    content: '\e995';
  }
  &.#{variables.$keyrune_prefix}-khc::before {
    content: '\e9c5';
  }
  &.#{variables.$keyrune_prefix}-ltc::before {
    content: '\e9b7';
  }

  // rarity border colors
  &.#{variables.$keyrune_prefix}-common {
    color: #fff;
  }
  &.#{variables.$keyrune_prefix}-uncommon::after,
  &.#{variables.$keyrune_prefix}-rare::after,
  &.#{variables.$keyrune_prefix}-mythic::after {
    color: #000;
  }
}
