@use '../../../styles/variables.scss' as variables;

.radioButtons {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  position: relative;
  width: 100%;
  > label {
    color: #fff;
    display: block;
    font-size: 0.875rem;
    font-weight: 900;
    left: 4px;
    position: relative;
    top: -2px;
    width: 100%;
  }
  .buttonGroup {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    padding: 0 5px;
    margin: 0 auto;
    width: 100%;
    input[type='radio'] {
      -moz-osx-font-smoothing: grayscale;
      -webkit-appearance: none;
      -webkit-font-smoothing: antialiased;
      appearance: none;
      color: variables.$primary-color;
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'icomoon' !important;
      font-size: 1.2rem;
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      line-height: 1;
      margin-right: 8px;
      speak: never;
      text-transform: none;
      &:before {
        content: '\ea56';
      }
      &:checked {
        &:before {
          content: '\ea54';
        }
      }
    }
    label {
      font-size: 0.8rem;
    }
    > .container {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      input {
        height: 20px;
        margin: 3px auto;
        width: 20px;
      }
    }
    .col1 {
      width: 100%;
    }
    .col2 {
      width: 50%;
    }
    .col3 {
      width: 33.3333%;
    }
    .col4 {
      width: 25%;
    }
  }
}

@media screen and (max-width: 767px) {
  .radioButtons {
    margin: 10px auto 0;
    > label {
      left: 4px;
    }
  }
}
