@use '../../../styles/variables.scss' as variables;

.inputText {
  position: relative;
  label {
    color: variables.$text-color;
    display: block;
    font-size: 0.875rem;
    font-weight: 900;
    left: 5px;
    margin-bottom: 5px;
    position: relative;
    width: 100%;
    span {
      &.required {
        color: #fd1f33;
      }
    }
  }
  input,
  textarea {
    background: variables.$input-background;
    border: none;
    border-radius: 4px;
    color: variables.$text-color;
    font-size: 0.875rem;
    font-weight: 400;
    min-height: 40px;
    padding: 10px;
    outline: none;
    resize: none;
    width: 100%;
    &.search {
      padding: 10px 10px 10px 40px;
    }
    &.withFocus {
      -webkit-appearance: none;
      appearance: none;
      box-shadow: 0 0 3px 2px #719ece;
    }
    &:disabled {
      opacity: 0.5;
    }
  }
  .searchIcon {
    bottom: 0;
    position: absolute;
    left: 13px;
    top: 12px;
  }
  .toggle {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.1rem;
    position: absolute;
    right: 8px;
    top: 50%;
    [class^='icon-eye'],
    [class*=' icon-eye'] {
      color: variables.$primary-color;
      &:hover {
        color: variables.$secondary-color;
      }
    }
  }
  textarea {
    &.resizable {
      resize: vertical;
    }
    min-height: 400px;
  }
  &.withErrors input,
  &.withErrors textarea {
    border: 1px solid #fd1f33 !important;
  }
}
