@use './variables' as variables;

@font-face {
  font-family: 'icomoon';
  src: url('../assets/fonts/icomoon/icomoon.eot?r55f5v');
  src:
    url('../assets/fonts/icomoon/icomoon.eot?r55f5v#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/icomoon/icomoon.ttf?r55f5v') format('truetype'),
    url('../assets/fonts/icomoon/icomoon.woff?r55f5v') format('woff'),
    url('../assets/fonts/icomoon/icomoon.svg?r55f5v#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: variables.$gold;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-size: 1rem;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: never;
  text-transform: none;
}

.icon-arrow-up:before {
  content: '\ea3a';
}
.icon-arrow-down:before {
  content: '\ea3e';
}
.icon-pencil:before {
  content: '\e905';
}
.icon-menu:before {
  content: '\e9bd';
}
.icon-eye:before {
  content: '\e9ce';
}
.icon-eye-blocked:before {
  content: '\e9d1';
}
.icon-plus:before {
  content: '\ea0a';
}
.icon-minus:before {
  content: '\ea0b';
}
.icon-exit:before {
  content: '\ea14';
}
.icon-location:before {
  content: '\e948';
}
.icon-map:before {
  content: '\e94c';
}
.icon-clock:before {
  content: '\e94e';
}
.icon-calendar:before {
  content: '\e953';
}
.icon-circle-right:before {
  content: '\ea42';
}
.icon-home:before {
  content: '\e901';
}
.icon-image:before {
  content: '\e90d';
}
.icon-articles:before {
  content: '\e926';
}
.icon-cache:before {
  content: '\e964';
}
.icon-direct-message:before {
  content: '\e96b';
}
.icon-group-direct-message:before {
  content: '\e96c';
}
.icon-profile:before {
  content: '\e971';
}
.icon-users:before {
  content: '\e972';
}
.icon-search:before {
  content: '\e986';
}
.icon-lock:before {
  content: '\e98f';
}
.icon-unlocked:before {
  content: '\e990';
}
.icon-wrench:before {
  content: '\e991';
}
.icon-filter:before {
  content: '\e992';
}
.icon-settings:before {
  content: '\e994';
}
.icon-bug:before {
  content: '\e999';
}
.icon-fire:before {
  content: '\e9a9';
}
.icon-bin:before {
  content: '\e9ad';
}
.icon-star-full:before {
  content: '\e9d9';
}
.icon-cancel-circle:before {
  content: '\ea0d';
}
.icon-cross:before {
  content: '\ea0f';
}
.icon-checkmark:before {
  content: '\ea10';
}
.icon-enter:before {
  content: '\ea13';
}
.icon-flip:before {
  content: '\ea2e';
}
.icon-back:before {
  content: '\ea40';
}
.icon-checkbox-checked:before {
  content: '\ea52';
}
.icon-checkbox-unchecked:before {
  content: '\ea53';
}
.icon-radio-checked:before {
  content: '\ea54';
}
.icon-radio-unchecked:before {
  content: '\ea56';
}
.icon-scissors:before {
  content: '\ea5a';
}
.icon-facebook:before {
  content: '\ea91';
}
.icon-instagram:before {
  content: '\ea92';
}
.icon-youtube:before {
  content: '\ea9d';
}
