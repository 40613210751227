@use 'sass:color';

:root {
  --backtotop-bgcolor: #3cb371;
  --backtotop-opacity: 0.9;
}

$body-bg: linear-gradient(135deg, #3cb371, #000);
$body-bg2: linear-gradient(135deg, #1a4731 80%, #000 100%);

$primary-color: #3cb371;
$primary-color-darker: color.adjust($primary-color, $lightness: -8%);
$primary-color-lighter: #3ec47a;
$secondary-color: #1a4731;
$secondary-color-darker: color.adjust($secondary-color, $lightness: -15%);
$secondary-color-lighter: color.adjust($secondary-color, $lightness: 13%);
$tertiary-color: #a8ff9b;
$tertiary-color-darker: #8ad07f;

$content-bg: linear-gradient(90deg, $primary-color-darker, $secondary-color);
$content-bg-inverted: linear-gradient(
  270deg,
  $primary-color-darker,
  $secondary-color
);
$content-bg-180: linear-gradient(
  180deg,
  $primary-color-darker,
  $secondary-color
);

$card-border-color: #17150f;
$disabled: #303030;
$gold: #ffd700;
$gold-darker: color.adjust($gold, $lightness: -8%);
$input-background: #1c1c1c;
$light-gray: #dadadd;
$neon-green-box-shadow: 0 0 13px rgba(173, 255, 47, 0.8);
$search-results-bg: #fdffff;
$text-color: #fff;
$warning: #fd1f33;
