@use '../../../../../styles/variables.scss' as variables;

.synergies {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  h3 {
    color: variables.$text-color;
    font-family: 'AvenirHeavy';
    margin: 10px auto;
  }
  > p {
    color: variables.$text-color;
    font-family: 'AvenirHeavy';
    font-size: 0.8rem;
    font-weight: 900;
    margin: 10px auto;
    padding: 10px;
  }
  .affiliateNote {
    color: #dedede;
    font-size: 0.75rem;
  }
  .cards {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .container {
    margin: 10px auto;
  }
  .synergy {
    margin: 10px auto;
  }
  .synergyButton {
    background-color: variables.$card-border-color;
    margin: 20px auto 0;
    width: 300px;
    &:hover {
      background-color: #333333;
    }
  }
}

@media screen and (max-width: 767px) {
  .synergies {
    width: 95%;
  }
}
