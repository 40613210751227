.cardLinks {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto 20px;
  width: 100%;
  > .card {
    height: 390px;
    margin: 10px 5px;
    width: 30%;
    .cardImageContainer {
      padding-bottom: 389px !important;
      width: 280px;
      > img {
        width: 280px;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .cardLinks {
    width: 100%;
    > .card {
      height: 320px;
      margin: 10px 10px;
      width: 30%;
      .cardImageContainer {
        padding-bottom: 319px !important;
        width: 230px;
        > img {
          width: 230px;
        }
      }
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .cardLinks {
    width: 100%;
    > .card {
      height: 417px;
      width: 45%;
      .cardImageContainer {
        padding-bottom: 417px !important;
        width: 300px;
        > img {
          width: 300px;
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .cardLinks {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 95%;
    > .card {
      height: 417px;
      width: 100%;
      .cardImageContainer {
        padding-bottom: 417px !important;
        width: 300px;
        > img {
          width: 300px;
        }
      }
    }
  }
}
