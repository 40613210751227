@use '../../../styles/variables.scss' as variables;

.articles {
  display: flex;
  flex-direction: column;
  background-color: variables.$search-results-bg;
  margin: 68px auto 0;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  > .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 auto 20px;
    min-height: 74.5vh;
    position: relative;
    width: 100%;
  }
  > .pagination {
    margin: 0 auto;
    &.bottomPagination {
      margin-bottom: 30px;
    }
    &.topPagination {
      margin-bottom: 20px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .articles {
    > .container {
      min-height: 77vh;
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .articles {
    margin: 50px auto 0;
    > .container {
      min-height: 68vh;
    }
  }
}

@media screen and (max-width: 575px) {
  .articles {
    margin: 50px auto 0;
    > .container {
      min-height: 70vh;
    }
  }
}
