@use '../../../styles/variables.scss' as variables;

.login {
  background: variables.$body-bg;
  height: 100%;
  width: 100%;
  .columnContainer {
    display: flex;
    height: 100%;
    .column {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      width: 50%;
      &:nth-child(2) {
        > div {
          height: 10%;
        }
        .signupInfo {
          z-index: 2;
          > p {
            font-size: 1.2rem;
          }
        }
        > div.animatedCards {
          bottom: 50px;
          height: 80%;
        }
      }
    }
  }
  .links {
    align-items: center;
    color: variables.$text-color;
    display: flex;
    height: 20px;
    margin: 10px auto;
    justify-content: center;
    text-align: center;
    > a {
      color: variables.$gold;
      margin-left: 5px;
      text-decoration: none;
      &:hover {
        color: variables.$gold-darker;
      }
    }
    &.backToHome {
      cursor: pointer;
      left: 15px;
      position: absolute;
      top: 10px;
      > a {
        color: variables.$text-color;
        font-size: 1.2rem;
        > span {
          margin-right: 5px;
        }
      }
      &:hover {
        color: variables.$gold-darker;
        > a {
          color: variables.$gold-darker;
          > span {
            color: variables.$gold-darker;
          }
        }
      }
    }
    &.signup {
      margin: 15px auto;
    }
  }
  .loginSection {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 90%;
    .loginContainer {
      align-items: center;
      background: linear-gradient(0deg, #3cb371, #000);
      border-radius: 10px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      margin: 0 auto;
      position: relative;
      width: 100%;
      .authErrors {
        margin-top: 10px;
        width: 70%;
        text-align: center;
      }
      .inputFields {
        width: 70%;
        margin: 140px auto 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        > div {
          margin: 10px 0;
        }
      }
      .logo {
        align-items: center;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        left: 0;
        margin: 10px auto 10px;
        position: absolute;
        right: 0;
        top: 0;
        width: 140px;
        .imageLoader {
          padding-bottom: 120px;
          &.imgContainer {
            width: 95%;
            img {
              cursor: default;
              height: auto;
              width: 100%;
            }
          }
        }
      }
      .submitBtnContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 30px auto 40px;
        position: relative;
        width: 100%;
        .errorMessages {
          position: relative;
        }
      }
    }
  }
}
.columnContainer {
  display: flex;
  height: 100%;
  .column {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 50%;
    &:nth-child(2) {
      > div {
        height: 15%;
      }
      .signupInfo {
        height: 5%;
        > p {
          font-size: 1.2rem;
        }
      }
      > div.animatedCards {
        bottom: 20px;
        height: 80%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .login {
    .columnContainer {
      .column:nth-child(1) {
        width: 100%;
      }
      .column:nth-child(2) {
        display: none;
      }
    }
    .loginSection {
      bottom: 40px;
      position: relative;
      .loginContainer {
        .submitBtnContainer {
          > button {
            width: 70%;
          }
        }
      }
    }
  }
}
