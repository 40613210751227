@use '../../../styles/variables.scss' as variables;

.cardsSearchResults {
  background: variables.$content-bg;
  > .cardsSearchBar {
    z-index: 3;
    &.simple {
      left: 0;
      position: absolute;
      right: 0;
      top: 85px !important;
      z-index: 2;
      animation: popIn 0.5s ease-out;
      height: 40px !important;
      left: 0;
      margin: 0 auto;
      min-height: unset !important;
      position: absolute;
      right: 0;
      > .searchBar {
        height: 40px !important;
        margin: 0 auto !important;
        min-height: unset !important;
        > form {
          > .searchInput {
            top: 0 !important;
          }
        }
      }
    }
  }
  > .pagination {
    &.bottomPagination {
      margin-bottom: 30px;
    }
    &.topPagination {
      margin-bottom: 20px;
      margin-top: 100px;
    }
  }
  > .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 120px;
    position: relative;
    top: 92px;
    &.advancedSearch {
      left: 0;
      position: absolute;
      top: 0;
      right: 0;
    }
    .resultsContainer {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      margin: 0 auto;
      min-height: 75.5vh;
      padding-bottom: 30px;
      position: relative;
      width: 90%;
      .column {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 10px auto;
        width: 25%;
      }
    }
  }
  .searchButton {
    position: absolute;
    border-radius: 22.5px;
    height: 45px;
    opacity: var(--backtotop-opacity);
    right: 40px;
    top: 16.5%;
    width: 45px;
    z-index: 2;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .cardsSearchResults {
    > .container {
      .resultsContainer {
        min-height: 80.5vh;
        .column {
          width: 33.3333%;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .cardsSearchResults {
    > .cardsSearchBar {
      &.simple {
        top: 61px !important;
      }
    }
    > .pagination {
      &.topPagination {
        margin-top: 70px;
      }
    }
    > .container {
      top: 70px !important;
      .resultsContainer {
        flex-direction: column;
        min-height: 75.5vh;
        .column {
          align-items: center;
          display: flex;
          justify-content: center;
          width: 100%;
        }
      }
      .searchButton {
        right: 20px;
      }
    }
    .searchButton {
      right: 20px;
    }
  }
}

@media screen and (max-width: 575px) {
  .cardsSearchResults {
    > .container {
      .resultsContainer {
        min-height: 68.5vh;
      }
    }
  }
}

@keyframes popIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  60% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}
