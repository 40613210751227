.cardImage {
  height: 334px;
  margin: 5px auto;
  position: relative;
  width: 240px;
  > span {
    cursor: default;
    display: block;
    .clickable {
      cursor: default;
    }
    &.clickable {
      cursor: pointer;
      .clickable {
        cursor: pointer;
      }
      > button {
        background: none;
        box-shadow: none;
        height: 100%;
        width: 100%;
        > span {
          &.cardImageContainer {
            border-radius: 4.75% / 3.5%;
            box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
            padding-bottom: 332px;
            position: unset;
            width: 100%;
            > img {
              border-radius: 4.75% / 3.5%;
              box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
            }
          }
        }
      }
    }
  }
  > a,
  span {
    > span {
      &.cardImageContainer {
        border-radius: 4.75% / 3.5%;
        box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
        padding-bottom: 332px;
        position: unset;
        width: 100%;
        > img {
          border-radius: 4.75% / 3.5%;
          box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
        }
      }
    }
    .cardSymbol {
      height: 16px;
      width: 16px;
      image {
        height: 100%;
        width: 100%;
      }
    }
  }
  .transform {
    align-items: center;
    border-radius: 17.5px;
    display: flex;
    height: 35px;
    justify-content: center;
    opacity: 0.9;
    position: absolute;
    right: 23px;
    top: 60px;
    width: 35px;
    z-index: 7;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .cardImage {
    height: 334px;
    margin: 10px auto;
    width: 240px;
    > a,
    span {
      > span {
        &.cardImageContainer {
          padding-bottom: 332px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .cardImage {
    height: 417px;
    width: 300px;
    > a,
    span {
      > span {
        &.cardImageContainer {
          padding-bottom: 417px;
        }
      }
    }
  }
}
