@use '../../../styles/variables.scss' as variables;

.sidebarContainer {
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  &.toggled {
    display: block;
  }
  .kofiIcon {
    height: 30px;
    width: auto;
  }
  .leftSidebar,
  .rightSidebar {
    background-color: variables.$secondary-color;
    color: white;
    height: 100%;
    overflow-y: auto;
    position: absolute;
    right: -500px;
    top: 0;
    transition: all 0.2s linear;
    width: 90px;
    z-index: 8;
  }
  .leftSidebar {
    left: 0;
  }
  .rightSidebar {
    box-shadow: variables.$neon-green-box-shadow;
    right: 0;
  }
  .leftSidebarOutside,
  .rightSidebarOutside {
    height: 100%;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 4;
  }
  .leftSidebar.toggled,
  .rightSidebar.toggled {
    transition: all 0.2s linear;
  }
  .leftSidebar.toggled {
    left: 0;
  }
  .rightSidebar.toggled {
    right: 0;
  }
  .border {
    width: 100%;
    height: 0.5px;
  }
  .linksContainer {
    border-radius: 5px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 5px;
    padding-bottom: 80px;
    button {
      &.links {
        background-color: unset;
        margin: 0 auto;
        padding: 5px;
        width: 100%;
        &.buttonLink {
          padding: 4px 5px;
        }
      }
    }
    .links {
      align-items: center;
      border: none;
      border-radius: 5px;
      color: inherit;
      cursor: pointer;
      display: flex;
      flex-flow: column nowrap;
      font-family: 'AvenirHeavy', Arial, Helvetica, sans-serif;
      font-size: 0.58rem;
      justify-content: center;
      padding-bottom: 8px;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      div,
      span {
        align-items: center;
        display: flex;
        height: 45px;
        justify-content: center;
        width: 100%;
        &::before {
          font-size: 1.813rem;
        }
      }
      &:hover {
        background-color: variables.$primary-color-darker;
      }
    }
    &.kofiContainer {
      background-color: variables.$secondary-color;
      border-radius: 0;
      box-shadow: 7px 2px 12px #adff2fcc;
      bottom: 0;
      height: 75px;
      padding-bottom: 0;
      position: fixed;
      right: 0;
      width: 90px;
    }
  }
  .changePassword {
    align-items: center;
    color: #2a8fbd;
    display: flex;
    font-size: 0.775rem;
    height: 50px;
    justify-content: center;
    padding: 5px;
    text-align: center;
    width: 100%;
  }
}
