@use '../../../../styles/variables.scss' as variables;

.cardsSearchBar {
  height: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  width: 100%;
}
