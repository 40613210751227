@use '../../../styles/variables.scss' as variables;

.component {
  height: 25px !important;
  margin: 0 auto;
  position: relative;
  width: 25px !important;
  .ringloader {
    height: 25px !important;
    width: 25px !important;
    span {
      height: 100%;
      width: 100%;
    }
  }
}

.fullscreen {
  background: rgba(0, 0, 0, 0.4);
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 10;
  .ringloader {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .wrapper {
    align-items: center;
    background: variables.$secondary-color;
    border-radius: 10px;
    box-shadow: 0 0 9px 5px rgba(255, 215, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    left: 0;
    margin: 0 auto;
    padding: 15px;
    position: absolute;
    top: 35%;
    right: 0;
    width: 300px;
    > p {
      font-size: 0.8rem;
      text-align: center;
    }
    .ringloader {
      left: 0;
      top: 0;
      transform: none;
    }
  }
}

.ringloader {
  display: inline-block;
  height: 50px;
  position: relative;
  width: 50px;
  span {
    animation: ringload 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border: 5px solid #fff;
    border-color: #fff transparent transparent transparent;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    height: 50px;
    position: absolute;
    width: 50px;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes ringload {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
