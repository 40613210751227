@use '../../../styles/variables.scss' as variables;

.componentModal {
  overflow-x: hidden;
  width: 98% !important;
  &::before {
    background: linear-gradient(360deg, #000, variables.$primary-color);
    border-radius: 12px;
    bottom: -10px;
    content: '';
    filter: blur(15px);
    left: -10px;
    opacity: 0.7;
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: -1;
  }
  > div {
    &.modalContent {
      height: 100% !important;
      margin: 0 auto;
      overflow-y: scroll;
      position: absolute;
      top: 0;
      width: 100% !important;
    }
    .modalBody {
      padding: 0 !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .componentModal {
    width: 100% !important;
  }
}
