@use './variables.scss' as variables;
/**
 * Rarities */

// hard-prefixing some of these to ensure they get included

@each $rarity, $text, $left, $right, $stroke in variables.$keyrune_palette {
  /* #{$rarity} */
  .#{variables.$keyrune_prefix}.#{variables.$keyrune_prefix}-#{$rarity} {
    color: $text;
    &.#{variables.$keyrune_prefix}-grad {
      // webkit outline/gradient
      /* Chrome, Safari4+ */
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0%, #{$left}),
        color-stop(50%, #{$right}),
        color-stop(100%, #{$left})
      );
      /* Chrome10+, Safari5.1+ */
      background: -webkit-linear-gradient(
        left,
        #{$left} 0%,
        #{$right} 50%,
        #{$left} 100%
      );
      -webkit-text-stroke: 0.03em #{$stroke};
      -webkit-text-fill-color: variables.$keyrune_text_fill;
      -webkit-background-clip: variables.$keyrune_background_clip;
      background-clip: variables.$keyrune_background_clip;

      &.#{variables.$keyrune-prefix}-no-border,
      &.#{variables.$keyrune-prefix}-duo {
        -webkit-text-stroke: 0;
      }
      &.#{variables.$keyrune-prefix}-duo::after {
        -webkit-text-fill-color: inherit;
      }
    }
  }
}

// hard coding foil rarity for now because it's so much more complex
.#{variables.$keyrune_prefix}-foil {
  color: #a6dced;
  &.#{variables.$keyrune_prefix}-grad {
    // webkit outline/gradient
    background: -webkit-linear-gradient(
      -45deg,
      #ea8d66 0%,
      #ea8d66 10%,
      #fdef8a 17%,
      #8bcc93 33%,
      #a6dced 50%,
      #6f75aa 67%,
      #e599c2 83%,
      #eeeeee 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      135deg,
      #ea8d66 0%,
      #ea8d66 15%,
      #fdef8a 28%,
      #8bcc93 42%,
      #a6dced 55%,
      #6f75aa 68%,
      #e599c2 84%,
      #e599c2 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    -webkit-text-stroke: 0.03em #333;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: variables.$keyrune_background_clip;

    &.#{variables.$keyrune-prefix}-no-border,
    &.#{variables.$keyrune-prefix}-duo {
      -webkit-text-stroke: 0;
    }
    &.#{variables.$keyrune-prefix}-duo::after {
      -webkit-text-fill-color: inherit;
    }
  }
}
