@use '../../../styles/variables.scss' as variables;

.backToTop {
  background-color: var(--backtotop-bgcolor);
  border-radius: 100px;
  bottom: -200px;
  display: block;
  height: 45px;
  margin: 0;
  opacity: var(--backtotop-opacity);
  padding: 0;
  position: fixed;
  right: 40px;
  transition: all 0.4s;
  width: 45px;
  z-index: 2;
  &.active {
    bottom: 150px;
  }
  button {
    background: none;
    border: none;
    display: block;
    height: 100%;
    left: 0;
    position: relative;
    right: 0;
    top: 0;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .backToTop {
    right: 20px;
    &.active {
      bottom: 50px;
    }
  }
}
