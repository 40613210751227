@use '../../../styles/variables.scss' as variables;

.searchInput {
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08);
  margin: 0 auto;
  position: relative;
  transition:
    box-shadow 0.3s ease,
    transform 0.2s ease; /* Smooth hover effect */
  width: 50%;
  input {
    background-color: variables.$secondary-color;
    width: 100%;
  }
  &.withChildren {
    input {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      width: 80%;
    }
  }
  .searchButtonContainer {
    position: absolute;
    right: 0;
    top: 0;
    width: 20%;
    > button {
      height: 40px;
    }
  }
  .searchIcon {
    bottom: 0;
    position: absolute;
    left: 13px;
    top: 12px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .searchInput {
    width: 70% !important;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .searchInput {
    width: 80% !important;
  }
}

@media screen and (max-width: 575px) {
  .searchInput {
    width: 90% !important;
  }
}
