@use '../../../styles/variables.scss' as variables;

.pagination {
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: space-evenly;
  position: relative;
  width: 350px;
  .buttonGroup {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 5px 0;
    width: 36%;
    > button {
      align-items: center;
      background-color: variables.$tertiary-color;
      border-radius: 4px;
      border-width: 0;
      color: variables.$gold !important;
      display: flex;
      height: 100%;
      justify-content: center;
      margin: 0 1px;
      > span {
        color: #10210e;
        font-family: 'AvenirHeavy';
        font-size: 0.85rem;
        font-weight: 600;
        line-height: 2;
      }
      &:hover {
        cursor: pointer;
      }
      &:disabled {
        background-color: variables.$tertiary-color-darker;
        cursor: not-allowed;
        opacity: 0.8;
      }
    }
    &.left {
      > button {
        &:nth-child(1) {
          width: 25%;
          > span {
            bottom: 2px;
            position: relative;
          }
        }
        &:nth-child(2) {
          width: 75%;
        }
      }
    }
    &.right {
      > button {
        &:nth-child(1) {
          width: 75%;
        }
        &:nth-child(2) {
          width: 25%;
          > span {
            bottom: 2px;
            position: relative;
          }
        }
      }
    }
  }
  .indicators {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 20%;
    span {
      color: variables.$tertiary-color;
      font-size: 0.9rem;
    }
  }
}
