@use 'sass:string';
/**
 * Globals */

$keyrune_version: '3.16.1' !default;
$keyrune_font_path: '../assets/fonts/keyrune' !default;

// font specific
$keyrune_font_style: normal !default;
$keyrune_font_variant: normal !default;
$keyrune_font_weight: normal !default;
$keyrune_font_size: 14px !default;
$keyrune_font_face: 'Keyrune' !default;
$keyrune_font: $keyrune_font_style $keyrune_font_variant $keyrune_font_weight
  calc($keyrune_font_size/1) 'Keyrune' !default;
$keyrune_prefix: 'ss' !default;
$keyrune_default_content: '\e684' !default;
$keyrune_background_clip: text !default;
$keyrune_text_fill: transparent !default;

// colors
$black: #000 !default;
$white: #fff !default;
$keyrune_palette: (
  ('common', #1a1718, #302b2c, #474040, #000),
  ('uncommon', #707883, #5a6572, #9e9e9e, #111),
  ('rare', #a58e4a, #876a3b, #dfbd6b, #333),
  ('mythic', #bf4427, #b21f0f, #f38300, #333),
  ('timeshifted', #652978, #652978, #c09ac5, #333)
) !default;

// sizes
$keyrune_sizes: (
  '2x': 2em,
  '3x': 3em,
  '4x': 4em,
  '5x': 5em,
  '6x': 6em,
) !default;

// special corrections for certain symbols when adding the border style
$border-corrections: (
  ('Vanguard', 'van', '\e655'),
  ('Archenemy', 'arc', '\e657'),
  ('Commander', 'cmd', '\e658'),
  ("Commander's Arsenal", 'cm1', '\e65a'),
  ('Commander 2013', 'c13', '\e65b'),
  ('Commander 2014', 'c14', '\e65d'),
  ('Commander 2015', 'c15', '\e900'),
  ('Planechase 2009', 'hop', '\e656'),
  ('Planechase 2012', 'pc2', '\e659'),
  ('Conspiracy', 'cns', '\e65c'),
  ('Shadows of the Past', 'sis', '\e9b2'),
  ('Innistrad Remastered', 'inr', '\e9e3'),
  ('Aetherdrift Commander', 'drc', '\e9ea')
) !default;

// dual-layered symbols (work in progress)
$dual-layered: (
  '40k': '\e99b',
  'afc': '\e982',
  'afr': '\e973',
  'c21': '\e97f',
  'clb': '\e992',
  'cc2': '\e988',
  'dmc': '\e997',
  'drc': '\e9eb',
  'dsc': '\e9de',
  'j21': '\e984',
  'khc': '\e9c6',
  'ltc': '\e9b8',
  'm3c': '\e9d1',
  'mid': '\e979',
  'otc': '\e9d3',
  'ncc': '\e990',
  'scd': '\e9ac',
  'una': '\e9bf',
) !default;

// sets
$keyrune_sets: (
  // core sets
  ('Alpha', 'lea', '\e600'),
  ('Beta', 'leb', '\e601'),
  ('Unlimited', '2ed', '\e602'),
  ('Revised', '3ed', '\e603'),
  ('4th Edition', '4ed', '\e604'),
  ('Summer Magic', 'psum', '\e605'),
  ('5th Edition', '5ed', '\e606'),
  ('6th Edition', '6ed', '\e607'),
  ('7th Edition', '7ed', '\e608'),
  ('8th Edition', '8ed', '\e609'),
  ('9th Edition', '9ed', '\e60a'),
  ('10th Edition', '10e', '\e60b'),
  ('Magic 2010', 'm10', '\e60c'),
  ('Magic 2011', 'm11', '\e60d'),
  ('Magic 2012', 'm12', '\e60e'),
  ('Magic 2013', 'm13', '\e60f'),
  ('Magic 2014', 'm14', '\e610'),
  ('Magic 2015', 'm15', '\e611'),
  ('Core background', 'bcore', '\e612'),
  ('Magic Origins', 'ori', '\e697'),
  ('Magic 2019', 'm19', '\e941'),
  ('Magic 2020', 'm20', '\e95d'),
  ('Alpha (MODO)', '1e', '\e947'),
  ('Beta (MODO)', '2e', '\e948'),
  ('Unlimited (MODO)', '2u', '\e949'),
  ('Revised (MODO)', '3e', '\e94a'),
  ('Core 2021', 'm21', '\e960'),
  ('Adventures in the Forgotten Realms', 'xdnd', '\e972'),
  ('Adventures in the Forgotten Realms', 'afr', '\e972'),
  ('Foundations', 'fdn', '\e9d8'),
  //
  // Artifact block
  ('Arabian Nights', 'arn', '\e613'),
  ('Antiquities', 'atq', '\e614'),
  ('Legends', 'leg', '\e615'),
  // Wizards block
  ('The Dark', 'drk', '\e616'),
  ('Fallen Empires', 'fem', '\e617'),
  ('Homelands', 'hml', '\e618'),
  // Ice Age block
  ('Ice Age', 'ice', '\e619'),
  ('Ice Age (Original)', 'ice2', '\e925'),
  ('Alliances', 'all', '\e61a'),
  ('Coldsnap', 'csp', '\e61b'),
  // Mirage block
  ('Mirage', 'mir', '\e61c'),
  ('Visions', 'vis', '\e61d'),
  ('Weatherlight', 'wth', '\e61e'),
  // Tempest block
  ('Tempest', 'tmp', '\e61f'),
  ('Stronghold', 'sth', '\e620'),
  ('Exodus', 'exo', '\e621'),
  // Urza's block
  ("Urza's Saga", 'usg', '\e622'),
  ("Urza's Legacy", 'ulg', '\e623'),
  ("Urza's Destiny", 'uds', '\e624'),
  // Mercadian block
  ('Mercadian Masque', 'mmq', '\e625'),
  ('Nemesis', 'nem', '\e626'),
  ('Nemesis', 'nms', '\e626'),
  ('Prophecy', 'pcy', '\e627'),
  // Invasion block
  ('Invasion', 'inv', '\e628'),
  ('Planeshift', 'pls', '\e629'),
  ('Apocalypse', 'apc', '\e62a'),
  // Odyssey block
  ('Odyssey', 'ody', '\e62b'),
  ('Torment', 'tor', '\e62c'),
  ('Judgement', 'jud', '\e62d'),
  // Onslaught block
  ('Onslaught', 'ons', '\e62e'),
  ('Legions', 'lgn', '\e62f'),
  ('Scourge', 'scg', '\e630'),
  // Mirrodin block
  ('Mirrodin', 'mrd', '\e631'),
  ('Darksteel', 'dst', '\e632'),
  ('5th Dawn', '5dn', '\e633'),
  // Kamigawa block
  ('Champions of Kamigawa', 'chk', '\e634'),
  ('Betrayers of Kamigawa', 'bok', '\e635'),
  ('Saviors of Kamigawa', 'sok', '\e636'),
  // Ravnica block
  ('Ravnica', 'rav', '\e637'),
  ('Guildpact', 'gpt', '\e638'),
  ('Dissension', 'dis', '\e639'),
  // Time Spiral block
  ('Time Spiral', 'tsp', '\e63a'),
  ('Planeshift', 'plc', '\e63b'),
  ('Future Sight', 'fut', '\e63c'),
  // Lorwyn block
  ('Lorwyn', 'lrw', '\e63d'),
  ('Morningtide', 'mor', '\e63e'),
  // Shadowmoor block
  ('Shadowmoor', 'shm', '\e63f'),
  ('Eventide', 'eve', '\e640'),
  // Alara block
  ('Shards of Alara', 'ala', '\e641'),
  ('Conflux', 'con', '\e642'),
  ('Alara Reborn', 'arb', '\e643'),
  // Zendikar block
  ('Zendikar', 'zen', '\e644'),
  ('Worldwake', 'wwk', '\e645'),
  ('Rise of Eldrazi', 'roe', '\e646'),
  // Scars block
  ('Scars of Mirrodin', 'som', '\e647'),
  ('Mirrodin Besieged', 'mbs', '\e648'),
  ('New Phyrexia', 'nph', '\e649'),
  // Innistrad block
  ('Innistrad', 'isd', '\e64a'),
  ('Dark Ascension', 'dka', '\e64b'),
  ('Avacyn Restored', 'avr', '\e64c'),
  // Return to Ravnica block
  ('Return to Ravnica', 'rtr', '\e64d'),
  ('Gatecrash', 'gtc', '\e64e'),
  ("Dragon's Maze", 'dgm', '\e64f'),
  // Theros block
  ('Theros', 'ths', '\e650'),
  ('Born of the Gods', 'bng', '\e651'),
  ('Journey into Nyx', 'jou', '\e652'),
  // Khans block
  ('Khans of Tarkir', 'ktk', '\e653'),
  ('Fate Reforged', 'frf', '\e654'),
  ('Dragons of Tarkir', 'dtk', '\e693'),
  // Return to Zendikar block
  ('Battle for Zendikar', 'bfz', '\e699'),
  ('Oath of the Gatewatch', 'ogw', '\e901'),
  // Return to Innistrad block
  ('Shadows Over Innistrad', 'soi', '\e902'),
  ('Eldritch Moon', 'emn', '\e90b'),
  // Kaladesh block
  ('Kaladesh', 'kld', '\e90e'),
  ('Aether Revolt', 'aer', '\e90f'),
  // Amonkhet block
  ('Amonkhet', 'akh', '\e914'),
  ('Hour of Devastation', 'hou', '\e924'),
  // Ixalan block
  ('Ixalan', 'xln', '\e92e'),
  ('Rivals of Ixalan', 'rix', '\e92f'),
  // Dominaria block
  ('Dominaria', 'dom', '\e93f'),
  // Guilds of Ravnica block
  ('Guilds of Ravnica', 'grn', '\e94b'),
  ('GRN Guild Kits', 'gk1', '\e94b'),
  ('Ravnica Allegiance', 'rna', '\e959'),
  ('RNA Guild Kits', 'gk2', '\e959'),
  ('War of the Spark', 'war', '\e95a'),
  // Eldraine block
  ('Throne of Eldraine', 'eld', '\e95e'),
  // 2020 sets
  ('Theros: Beyond Death', 'thb', '\e961'),
  ('Ikoria: Lair of Behemoths', 'iko', '\e962'),
  ('Zendikar Rising', 'znr', '\e963'),
  // 2021 sets
  ('Kaldheim', 'khm', '\e974'),
  ('Strixhaven: School of Mages', 'stx', '\e975'),
  ('Innistrad: Midnight Hunt', 'mid', '\e978'),
  ('Innistrad: Crimson Vow', 'vow', '\e977'),
  // 2022 sets
  ('Kamigawa: Neon Dynasty', 'neo', '\e98c'),
  ('Streets of New Capenna', 'snc', '\e98b'),
  ('Dominaria United', 'dmu', '\e993'),
  ("The Brothers' War", 'bro', '\e99d'),
  // 2023 sets
  ('Phyrexia: All Will Be One', 'one', '\e9a1'),
  ('March of the Machine', 'mom', '\e9a2'),
  ('March of the Machine: The Aftermath', 'mat', '\e9a3'),
  ('Wilds of Eldraine', 'woe', '\e9ae'),
  ('Lost Caverns of Ixalan', 'lci', '\e9c2'),
  // 2024 Sets
  ('Murders at Karlov Manor', 'mkm', '\e9c9'),
  ('Outlaws of Thunder Junction', 'otj', '\e9cc'),
  ('Bloomburrow', 'blb', '\e9cd'),
  ('Duskmourn: House of Horror', 'dsk', '\e9d7'),
  // 2025 sets
  ('Aetherdrift', 'dft', '\e9e0'),
  ('Tarkir: Dragonstorm', 'tdm', '\e9e6'),
  // Command zone
  ('Vanguard', 'van', '\e655'),
  ('Planechase 2009', 'hop', '\e656'),
  ('Archenemy', 'arc', '\e657'),
  ('Commander', 'cmd', '\e658'),
  ('Planechase 2012', 'pc2', '\e659'),
  ("Commander's Arsenal", 'cm1', '\e65a'),
  ('Commander 2013', 'c13', '\e65b'),
  ('Conspiracy', 'cns', '\e65c'),
  ('Commander 2014', 'c14', '\e65d'),
  ('Commander 2015', 'c15', '\e900'),
  ('Conspiracy 2, Take', 'cn2', '\e904'),
  ('Commander 2016', 'c16', '\e9e5'),
  ('Planechase Anthology', 'pca', '\e911'),
  ('Commander Anthology', 'cma', '\e916'),
  ('Archenemy: Nicol Bolas', 'e01', '\e92d'),
  ('Archenemy: Nicol Bolas', 'ann', '\e92d'),
  ('Explorers of Ixalan', 'e02', '\e931'),
  ('Commander 2017', 'c17', '\e934'),
  ('Commander Anthology 2', 'cm2', '\e940'),
  ('Battlebond', 'bbd', '\e942'),
  ('Commander 2018', 'c18', '\e946'),
  ('Commander 2019', 'c19', '\e95f'),
  ('Ikoria: Commander 2020', 'c20', '\e966'),
  ('Zendikar Rising: Commander Decks', 'znc', '\e967'),
  ('Commander Collection: Green', 'cc1', '\e968'),
  ('Commander Legends', 'cmr', '\e969'),
  ('Commander Legends Decks', 'cmc', '\e969'),
  ('Kaldheim Commander', 'khc', '\e97d'),
  ('Commander 2021', 'c21', '\e97e'),
  ('Forgotten Realms Commander', 'afc', '\e981'),
  ('Innistrad: Midnight Hunt Commander', 'mic', '\e985'),
  ('Innistrad: Crimson Vow Commander', 'voc', '\e986'),
  ('Commander Collection: Black', 'cc2', '\e987'),
  ('Kamigawa: Neon Dynasty Commander', 'nec', '\e98d'),
  ('Streets of New Capenna Commander', 'ncc', '\e98e'),
  ("Commander Legends: Battle for Baldur's Gate", 'clb', '\e991'),
  ('Dominaria United Commander', 'dmc', '\e994'),
  ('Warhammer 40K', '40k', '\e998'),
  ("The Brothers' War Commander", 'brc', '\e99f'),
  ('Phyrexia: All Will Be One Commander', 'onc', '\e9a8'),
  ('March of the Machines Commander', 'moc', '\e9a9'),
  ('Starter Commander Decks', 'scd', '\e9ab'),
  ('Commander Masters', 'cmm', '\e9b5'),
  ('The Lord of the Rings: Tales of Middle-Earth Commander', 'ltc', '\e9b6'),
  ('Wilds of Eldraine Commander', 'woc', '\e9b9'),
  ('Lost Caverns of Ixalan Commander', 'lcc', '\e9c7'),
  ('Murders at Karlov Manor Commander', 'mkc', '\e9ca'),
  ('Outlaws of Thunder Junction Commander', 'otc', '\e9d2'),
  ('Bloomburrow Commander', 'blc', '\e9d4'),
  ('Modern Horizons 3 Commander', 'm3c', '\e9d0'),
  ('Duskmourn Commander', 'dsc', '\e9dc'),
  ('Foundations Commander', 'fdc', '\e9e4'),
  ('Aetherdrift Commander', 'drc', '\e9e8'),
  //
  // Reprint sets
  ('Chronicles', 'chr', '\e65e'),
  ('Anthologies', 'ath', '\e65f'),
  ('Battle Royale', 'brb', '\e660'),
  ('Beatdown', 'btd', '\e661'),
  ('Deckmasters', 'dkm', '\e662'),
  ('Modern Masters', 'mma', '\e663'),
  ('Modern Masters 2015', 'mm2', '\e695'),
  ('Eternal Masters', 'ema', '\e903'),
  ('Modern Masters 2017', 'mm3', '\e912'),
  ('Renaissance', 'xren', '\e917'),
  ('Rinascimento', 'xrin', '\e918'),
  ('Iconic Masters', 'ima', '\e935'),
  ('Masters 25', 'a25', '\e93d'),
  ('Ultimate Masters', 'uma', '\e958'),
  ('Modern Horizons', 'mh1', '\e95b'),
  ('Double Masters', '2xm', '\e96e'),
  ('Jumpstart', 'jmp', '\e96f'),
  ('Mystery Booster', 'mb1', '\e971'),
  ('Modern Horizons 2', 'mh2', '\e97b'),
  ('Strixhaven: Mystical Archives', 'sta', '\e980'),
  ('Jumpstart: Historic Horizons', 'j21', '\e983'),
  ('Double Masters 2022', '2x2', '\e99c'),
  ("The Brothers' War Retro Artifacts", 'brr', '\e9a0'),
  ('Jumpstart 2022', 'j22', '\e9ad'),
  ('Multiverse Legends', 'mul', '\e9ba'),
  ('Wilds of Eldraine Enchanting Tales', 'wot', '\e9c0'),
  ('Battle Royale (alternate)', 'br', '\e9c1'),
  ('Special Guests', 'spg', '\e9c8'),
  ('Breaking News', 'otp', '\e9d5'),
  ('The Big Score', 'big', '\e9d6'),
  ('Modern Horizons 2 Timeshifts', 'h2r', '\e97b'),
  ('Mystery Booster 2', 'mb2', '\e9d9'),
  ('Jumpstart 2025 (Alternate)', 'j25a', '\e9db'),
  ('Jumpstart 2025', 'j25', '\e9df'),
  ('Pioneer Masters', 'pio', '\e9e7'),
  //
  // Beginner
  ('Portal', 'por', '\e664'),
  ('Portal 2', 'p02', '\e665'),
  ('Portal 2', 'po2', '\e665'),
  ('Portal 3 Kingdoms', 'ptk', '\e666'),
  ('Starter 1999', 's99', '\e667'),
  ('Starter 2000', 's00', '\e668'),
  ('Welcome Deck 2016', 'w16', '\e907'),
  ('Welcome Deck 2017', 'w17', '\e923'),
  //
  // Duel Decks
  ('Elves vs. Goblins', 'evg', '\e669'),
  ('Jace vs. Chandra', 'dd2', '\e66a'),
  ('Divine vs. Demonic', 'ddc', '\e66b'),
  ('Garruk vs. Liliana', 'ddd', '\e66c'),
  ('Phyrexia vs.', 'dde', '\e66d'),
  ('Elspeth vs.', 'ddf', '\e66e'),
  ('Knights vs. Dragons', 'ddg', '\e66f'),
  ('Ajani vs. Nicol', 'ddh', '\e670'),
  ('Venser vs. Koth', 'ddi', '\e671'),
  ('Izzet vs. Golgari', 'ddj', '\e672'),
  ('Sorin vs. Tibalt', 'ddk', '\e673'),
  ('Heroes vs. Monsters', 'ddl', '\e674'),
  ('Jace vs. Vraska', 'ddm', '\e675'),
  ('Speed vs. Cunning', 'ddn', '\e676'),
  ('Kiora vs. Elspeth', 'ddo', '\e677'),
  ('Zendikar vs.', 'ddp', '\e698'),
  ('Blessed vs. Cursed', 'ddq', '\e908'),
  ('Nissa vs. Ob Nixilis', 'ddr', '\e90d'),
  ('New Phyrexia vs. Mirrodin Pure', 'td2', '\e91c'),
  ('Mind vs. Might', 'dds', '\e921'),
  ('Merfolk vs. Goblins', 'ddt', '\e933'),
  ('Elves vs. Inventors', 'ddu', '\e93e'),
  //
  // From the Vaults
  ('Dragons', 'drb', '\e678'),
  ('Exiled', 'v09', '\e679'),
  ('Relics', 'v10', '\e67a'),
  ('Legends', 'v11', '\e67b'),
  ('Realms', 'v12', '\e67c'),
  ('Twenty', 'v13', '\e67d'),
  ('Annihilation', 'v14', '\e67e'),
  ('Angels', 'v15', '\e905'),
  ('Lore', 'v16', '\e906'),
  ('Vaults', 'v0x', '\e920'),
  ('Transform', 'v17', '\e939'),
  //
  // Premium Deck Series
  ('Slivers', 'h09', '\e67f'),
  ('Fire & Lightning', 'pd2', '\e680'),
  ('Graveborn', 'pd3', '\e681'),
  ('Modern Event Deck', 'md1', '\e682'),
  //
  // Signature Spellbook
  ('Jace', 'ss1', '\e944'),
  ('Gideon', 'ss2', '\e95c'),
  ('Chandra', 'ss3', '\e96d'),
  //
  // Global Series
  ('Jiang Yanggu & Mu Yanling', 'gs1', '\e945'),
  //
  // Guild Kits
  ('Azorius', 'azorius', '\e94e'),
  ('Boros', 'boros', '\e94f'),
  ('Dimir', 'dimir', '\e950'),
  ('Golgari', 'golgari', '\e951'),
  ('Gruul', 'gruul', '\e952'),
  ('Izzet', 'izzet', '\e953'),
  ('Orzhov', 'orzhov', '\e954'),
  ('Rakdos', 'rakdos', '\e955'),
  ('Selesnya', 'selesnya', '\e956'),
  ('Simic', 'simic', '\e957'),
  //
  // Other supplemental products
  ('Game Night', 'gnt', '\e94d'),
  ('Game Night 2019', 'gn2', '\e964'),
  ('Time Spiral Remastered', 'tsr', '\e976'),
  ('Dominaria Remastered', 'dmr', '\e9a4'),
  ('Game Night: Free for All', 'gn3', '\e9a5'),
  ('The Lord of the Rings: Tales of Middle-Earth', 'ltr', '\e9af'),
  ('Universes Beyond: Doctor Who', 'who', '\e9b0'),
  ('Ravnica Remastered', 'rvr', '\e9bb'),
  ('Universes Beyond: Fallout', 'pip', '\e9c3'),
  ('Ravnica: Clue Edition', 'clu', '\e9cb'),
  ("Universes Beyond: Assassin's Creed", 'acr', '\e9ce'),
  ('Modern Horizons 3', 'mh3', '\e9cf'),
  ('Innistrad Remastered', 'inr', '\e9e2'),
  //
  // Promos
  ('Guru', 'pgru', '\e683'),
  ('Magic symbol', 'pmtg1', '\e684'),
  ('Magic symbol', 'pmtg2', '\e685'),
  ('Leaf', 'pleaf', '\e686'),
  ('Media Insert', 'pmei', '\e687'),
  ('DCI (Arena)', 'parl', '\e688'),
  ('Dragons', 'dpa', '\e689'),
  ('Book Insert', 'pbook', '\e68a'),
  ('Astral', 'past', '\e68b'),
  ('Arena logo', 'parl2', '\e68c'),
  ('Zendikar', 'exp', '\e69a'),
  ('Salvat 2005', 'psalvat05', '\e909'),
  ('Salvat 2011', 'psalvat11', '\e90a'),
  ('Kaladesh Inventions', 'mp1', '\e913'),
  ('Kaladesh Inventions', 'mps', '\e913'),
  ('Xbox Media Promo', 'pxbox', '\e915'),
  ('Magic Premiere Shop', 'pmps', '\e919'),
  ('Mirrodin Pure', 'pmpu', '\e91a'),
  ('Amonkhet Invocations', 'mp2', '\e922'),
  ('IDW Promo', 'pidw', '\e92c'),
  ('Dragon*Con Promo', 'pdrc', '\e932'),
  ('Phoenix Heart Celebration', 'pheart', '\e936'),
  ('HasCon 2017', 'h17', '\e938'),
  ('Duelist Extra Pulled', 'pdep', '\e93a'),
  ('Sega Dreamcast', 'psega', '\e93b'),
  ("The Sorcerer's Apprentice", 'ptsa', '\e93c'),
  ('Arena (MTGO)', 'parl3', '\e943'),
  ('Heroes of the Realm 2016', 'htr', '\e687'),
  ('Mythic Edition', 'med', '\e94c'),
  ('Ponies: the Galloping', 'ptg', '\e965'),
  ('Heroes of the Realm 2017', 'htr17', '\e687'),
  ('Judge Academy 2020', 'j20', '\e96a'),
  ('Zendikar Rising Expeditions', 'zne', '\e97a'),
  ("The Brothers' War Transformers", 'bot', '\e99e'),
  ('Jurassic World', 'rex', '\e9c4'),
  //
  // Secret Lairs
  ('Secret Lair: Ultimate Edition', 'slu', '\e687'),
  ('Secret Lair Drop', 'sld', '\e687'),
  ('Secret Lair Drop Promos', 'psld', '\e687'),
  ('Secret Lair Logo', 'sld2', '\e9bc'),
  //
  // Online only sets
  ('Masters Edition', 'me1', '\e68d'),
  ('Masters Edition II', 'me2', '\e68e'),
  ('Masters Edition III', 'me3', '\e68f'),
  ('Masters Edition IV', 'me4', '\e690'),
  ('Tempest Remastered', 'tpr', '\e694'),
  ('Vintage Masters', 'vma', '\e696'),
  ('Legendary Cube', 'xlcu', '\e90c'),
  ('Legendary Cube', 'pz1', '\e90c'),
  ('Magic Online', 'modo', '\e91b'),
  ('Magic Online', 'pmodo', '\e91b'),
  ('Magic Duels', 'duels', '\e91d'),
  ('Magic Duels', 'xduels', '\e91d'),
  ('Magic Online Deck Series', 'xmods', '\e91e'),
  ('Treasure Chests', 'pz2', '\e91f'),
  ('Historic Anthology 1', 'ha1', '\e96b'),
  ('Amonkhet Remastered', 'akr', '\e970'),
  ('Kaladesh Remastered', 'klr', '\e97c'),
  ('Alchemy 2022', 'y22', '\e989'),
  ("Alchemy Horizons: Baldur's Gate", 'hbg', '\e9a6'),
  ('Alchemy 2023', 'y23', '\e9a7'),
  ('Alchemy: Dominaria', 'ydmu', '\e9a7'),
  ('Shadows Over Innistrad', 'sir', '\e9b1'),
  ('Shadows of the Past', 'sis', '\e9b2'),
  ('Explorer Anthology 1', 'ea1', '\e9b4'),
  ('Alchemy 2024', 'y24', '\e9bd'),
  ('Alchemy 2025', 'y25', '\e9da'),
  ('Alchemy Bloomburrow', 'yblb', '\e9da'),
  //
  // Unsets
  ('Unglued', 'ugl', '\e691'),
  ('Unhinged', 'unh', '\e692'),
  ('Unstable', 'ust', '\e930'),
  ('Unsanctioned', 'und', '\e96c'),
  ('Unfinity', 'unf', '\e98a'),
  ('Unfinity Acorns', 'una', '\e9be'),
  //
  // Unoffocial
  ("Collector's Edition", 'xcle', '\e926'),
  ("International Collector's Edition", 'xice', '\e927'),
  ('Two Player Introductory Set', 'x2ps', '\e928'),
  ('Alternate 4th Edition', 'x4ea', '\e929'),
  ('APAC Lands', 'papac', '\e92a'),
  ('Euro Lands', 'peuro', '\e92b'),
  ('Friday Night Magic', 'pfnm', '\e937'),
  ('30th Anniversary Edition', '30a', '\e9aa')
) !default;

// display glyph correctly
@function ss-content($glyph) {
  @return string.unquote('"#{$glyph}"');
}
