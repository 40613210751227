@use '../../../styles/variables.scss' as variables;

.topbar {
  background: variables.$body-bg2;
  box-shadow: variables.$neon-green-box-shadow;
  height: 50px;
  left: 0;
  padding: 0px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 8;
  .container {
    height: 50px;
    position: relative;
    width: 100%;
    .logo {
      align-items: center;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      left: 0;
      margin: 0 auto 10px;
      position: absolute;
      right: 0;
      top: 0;
      width: 50px;
      .imgContainer {
        padding-bottom: 50px;
        width: 100%;
      }
      img {
        cursor: default;
        height: auto;
        width: 100%;
      }
    }
    .menu {
      right: 15px;
      span {
        display: block;
      }
    }
    [class^='icon-'],
    [class*=' icon-'] {
      font-size: 1.4rem;
    }
    .menuBtn {
      left: 10px;
      z-index: 1;
      span {
        display: block;
      }
    }
    button {
      background-color: inherit;
      border: none;
      display: block;
      margin: 0;
      outline: none;
      padding: 0;
      position: absolute;
      text-decoration: none;
      top: 50%;
      transform: translateY(-50%);
    }
    .searchIcon {
      position: absolute;
      right: 35px;
    }
  }
}

@media screen and (min-width: 768px) {
  .topbar {
    height: 68px;
    .container {
      height: 68px;
      .logo {
        width: 70px;
        .imgContainer {
          padding-bottom: 70px;
        }
      }
    }
  }
}
